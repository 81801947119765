import { createSlice } from '@reduxjs/toolkit';

interface IToast{
    severity?: string;
    summary?: string;
    detail?: string;
}

export interface ToastInitialState {
    toastMessage: IToast;
}

const initialState: ToastInitialState = {
    toastMessage: {}
}

export const toastSlice = createSlice({
    name : 'toast',
    initialState,
    reducers: {
        setToastMessage: (state, action) =>{
            state.toastMessage = action.payload;
        }
    }
});

export const { setToastMessage } = toastSlice.actions;

export default toastSlice.reducer;

export const mostrarToast = (payload: IToast) => (dispatch: any) => {
    dispatch(setToastMessage(payload));
}