import React, { useEffect, useState } from "react";
import { Container } from "../../components/Container";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { IConductor } from "./Conductores.type";
import CrearConductor from "./CrearConductor";
import EditarConductor from "./EditarConductor";
import { formatDate } from "../../util/DateUtil";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchConductoresWithIngresos, ingresarConductor, retirarConductor } from "../../redux/conductores";
import ModalConfirmacion from "../../components/ModalConfirmacion";

const ListaConductores = () => {

  const dispatch = useAppDispatch();

  const { listaTablaConductores: listaConductores, loading } = useAppSelector((state) => state.conductores);

  const [visibleCrear, setVisibleCrear] = useState(false);

  const [visibleEditar, setVisibleEditar] = useState(false);

  const [visibleRetirar, setVisibleRetirar] = useState(false);

  const [visibleIngresar, setVisibleIngresar] = useState(false);

  const [conductor, setConductor] = useState<IConductor>({});

  const [filters, setFilters] = useState({});

  useEffect(() => {
    dispatch(fetchConductoresWithIngresos());
  }, [dispatch]);


  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const retirarConductorSeleccionado = () : void => {
    dispatch(retirarConductor(conductor.documento!, setVisibleRetirar));
  }

  const ingresarConductorSeleccionado = () : void => {
    dispatch(ingresarConductor(conductor.documento!, setVisibleIngresar));
  }

  const initFilters = () => {
    setFilters({
      nombre: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      empresa: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      documento: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Crear"
          icon="pi pi-plus"
          severity="success"
          onClick={() => setVisibleCrear(true)}
        />
        <Button
          label="Retirar"
          icon="pi pi-user-minus"
          severity="danger"
          onClick={() => setVisibleRetirar(true)}
          disabled={Object.keys(conductor).length === 0}
        />
        <Button
          label="Ingresar"
          icon="pi pi-user-plus"
          severity="warning"
          onClick={() => setVisibleIngresar(true)}
          disabled={Object.keys(conductor).length === 0}
        />
        <Button
          label="Editar"
          icon="pi pi-pencil"
          severity="info"
          onClick={() => setVisibleEditar(true)}
          disabled={Object.keys(conductor).length === 0}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button label="Exportar" icon="pi pi-upload" className="p-button-help" />
    );
  };

  const fechaBodyTemplate = (rowData: IConductor) => {
    return formatDate(new Date(rowData.fechaNacimiento!));
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-wrap justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Limpiar Filtros"
          outlined
          onClick={clearFilter}
        />
      </div>
    );
  };

  const header = renderHeader();

  return (
    <Container>
      <Toolbar
        className="mb-3"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <DataTable
        value={listaConductores}
        paginator
        rows={10}
        dataKey="documento"
        filters={filters}
        loading={loading}
        emptyMessage="No hay conductores."
        onSelectionChange={(e) => setConductor(e.value)}
        selectionMode="single"
        selection={conductor}
        header={header}
        showGridlines
      >
        <Column
          field="nombre"
          header="Nombre"
          filter
          filterField="nombre"
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="empresa"
          header="Empresa"
          filter
          filterField="empresa"
          style={{ minWidth: "6rem" }}
        />
        <Column
          hidden
          field="fechaNacimiento"
          header="Fecha de Nacimiento"
          dataType="date"
          body={fechaBodyTemplate}
          style={{ minWidth: "6rem" }}
        />
        <Column
          hidden
          field="tipoDocumento"
          header="Tipo de documento"
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="documento"
          header="Documento"
          filter
          filterField="documento"
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="fechaUltimoIngreso"
          header="Fecha Último Ingreso"
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="fechaUltimoRetiro"
          header="Fecha Último Retiro"
          style={{ minWidth: "6rem" }}
        />
      </DataTable>
      {visibleCrear === true && 
        <CrearConductor visible={visibleCrear} setVisible={setVisibleCrear} />
      }
      {visibleEditar === true && 
        <EditarConductor
        visible={visibleEditar}
        setVisible={setVisibleEditar}
        conductor={conductor}
        />
      }
      {visibleRetirar &&
          <ModalConfirmacion
              visible={visibleRetirar}
              header='Retirar Conductor'
              body='¿Estás seguro que deseas retirar al conductor?' 
              setVisible={setVisibleRetirar}
              action={retirarConductorSeleccionado}
          />
      }
      {visibleIngresar &&
          <ModalConfirmacion
              visible={visibleIngresar}
              header='Ingresar Conductor'
              body='¿Estás seguro que deseas ingresar al conductor?' 
              setVisible={setVisibleIngresar}
              action={ingresarConductorSeleccionado}
          />
      }
      
    </Container>
  );
};

export default ListaConductores;
