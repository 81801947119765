import axios from "axios";
import { history } from "./History";



export const axiosInstance = axios.create(
  {
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  }
);

//Request
axiosInstance.interceptors.request.use(function (config) {   
    const data = localStorage.getItem('jwtData');
    if(data){
        const jwtData = JSON.parse(data);
        config.headers.Authorization = 'Bearer ' + jwtData.token;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
});


//Response
axiosInstance.interceptors.response.use(function (response) {
    
    return response;
  }, function (error) {
    if(error.response.status === 401){
      localStorage.removeItem('jwtData');
      history.push('/login');
    }
    return Promise.reject(error);
});