import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Link, Outlet } from 'react-router-dom';

const Menu = () => {
  const [sidebar, setSidebar] = useState(false);

  return (
    <div className='layout'>
      <div className='w-full h-5rem relative'>
        <Sidebar visible={sidebar} onHide={() => setSidebar(false)}>
          <div className='flex flex-column justify-content-start mt-5'>
            <Link to={'/'}>
              <Button icon='pi pi-list' style={{border: 'none'}} className='text-xl p-5' label='Liquidaciones' type='button' link/>
            </Link>
            <Link to={'/salarios'}>
              <Button icon='pi pi-book' className='text-xl p-5' label='Salarios' type='button' link/>
            </Link>
            <Link to={'/buses'}>
              <Button icon='pi pi-car' className='text-xl p-5' label='Buses' type='button' link />
            </Link>
            <Link to={'/conductores'}>
              <Button icon='pi pi-user' className='text-xl p-5' label='Conductores' type='button' link/>
            </Link>
            <Link to={'/pasajes'}>
              <Button icon='pi pi-money-bill' className='text-xl p-5' label='Pasajes' type='button' link/>
            </Link>
            <Link to={'/deudas'}>
              <Button icon='pi pi-credit-card' className='text-xl p-5' label='Deudas' type='button' link/>
            </Link>
            <Link to={'/abonos'}>
              <Button icon='pi pi-dollar' className='text-xl p-5' label='Abonos' type='button' link/>
            </Link>
            <Link to={'/festivos'}>
              <Button icon='pi pi-calendar' className='text-xl p-5' label='Festivos' type='button' link/>
            </Link>
          </div>
        </Sidebar>
        <Button className='ml-2 mt-2' size='large' icon="pi pi-bars" onClick={() => setSidebar(true)}/>
      </div>
      <Outlet/>
    </div>
  );
};

export default Menu;