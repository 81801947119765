import React, { useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { IBus } from './Buses.type';
import CrearBus from './CrearBus';
import EditarBus from './EditarBus';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { fetchBuses } from '../../redux/buses';

const ListaBuses = () => {

    const dispatch = useAppDispatch();
    const { listaBuses, loading } = useAppSelector((state) => state.buses);

    const [visibleCrear, setVisibleCrear] = useState(false);

    const [visibleEditar, setVisibleEditar] = useState(false);

    const [bus, setBus] = useState<IBus>({});

    useEffect(() => {
        dispatch(fetchBuses());
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Crear" icon="pi pi-plus" severity="success" onClick={() => setVisibleCrear(true)}/>
                <Button label="Editar" icon="pi pi-pencil" severity="info" onClick={() => setVisibleEditar(true)} disabled={Object.keys(bus).length === 0}/>
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Exportar" icon="pi pi-upload" className="p-button-help"/>;
    };

    const rutaBodyTemplate = (rowData: IBus) => {
        let rutaNombre: string;
        switch(rowData.ruta) {
            case "CCNT": 
                rutaNombre = "Circular Coonatra";
                break;
            case "CSUR3":
                rutaNombre = "Circular Sur 303";
                break;
            case "CSUR2":
                rutaNombre = "Circular Sur 302";
                break;
            case "CBOS":
                rutaNombre = "Calasanz Boston";
                break;
            default:
                rutaNombre = "Circular Sur 303";
        }
        return rutaNombre;
    };

    return (
        <Container>
            <Toolbar className="mb-3" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <DataTable value={listaBuses} paginator rows={10} dataKey="numero" loading={loading} emptyMessage="No hay buses."
                onSelectionChange={(e) => setBus(e.value)} selectionMode="single" selection={bus} showGridlines
            >
                <Column field="numero" header="Número" style={{ minWidth: '12rem' }} />
                <Column field="empresa" header="Empresa" style={{ minWidth: '12rem' }} />
                <Column field="ruta" header="Ruta" dataType="date" style={{ minWidth: '12rem' }} body={rutaBodyTemplate}/>
                <Column field="modelo" header="Modelo" style={{ minWidth: '12rem' }} hidden/>
                <Column field="marca" header="Marca" style={{ minWidth: '12rem' }} hidden/>
                <Column field="conductorNombre" header="Conductor" style={{ minWidth: '12rem' }}/>
                <Column field="placa" header="Placa" style={{ minWidth: '12rem' }} />
            </DataTable>

            {visibleCrear && 
                <CrearBus visible={visibleCrear} setVisible={setVisibleCrear}/>
            }
            {visibleEditar &&
                <EditarBus visible={visibleEditar} setVisible={setVisibleEditar} bus={bus}/>
            }
        </Container>
    );
};

export default ListaBuses;