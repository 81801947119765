import { configureStore } from '@reduxjs/toolkit'
import liquidaciones from './liquidaciones';
import conductores from './conductores';
import buses from './buses';
import pasajes from './pasajes';
import toast from './toast';
import salarios from './salarios';
import deudas from './deudas';
import abonos from './abonos';
import festivos from './festivos';

export const store = configureStore({
  reducer: {
    liquidaciones,
    conductores,
    buses,
    pasajes,
    toast,
    salarios,
    deudas,
    abonos,
    festivos
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch