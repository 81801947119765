import React, { useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { IFestivo } from './Festivos.type';
import CrearFestivo from './CrearFestivo';
import { deleteFestivo, fetchFestivos } from '../../redux/festivos';
import ModalConfirmacion from '../../components/ModalConfirmacion';

const ListaFestivos = () => {
    const dispatch = useAppDispatch();
    const { listaFestivos, loading } = useAppSelector((state) => state.festivos);

    const [festivo, setFestivo] = useState<IFestivo>();
    const [visibleCrear, setVisibleCrear] = useState(false);
    const [visibleEliminar, setVisibleEliminar] = useState(false);

    useEffect(() => {
        dispatch(fetchFestivos());
      }, [dispatch]);


    const onDelete = () => {
        dispatch(deleteFestivo(festivo!.uuid!, setVisibleEliminar));
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button
                label="Crear"
                icon="pi pi-plus"
                severity="success"
                onClick={() => setVisibleCrear(true)}
                />
                <Button
                label="Eliminar"
                icon="pi pi-trash"
                severity="danger"
                disabled={festivo === undefined}
                onClick={() => setVisibleEliminar(true)}
                />
            </div>
        );
    };

    return (
        <Container>
            <Toolbar className="mb-3" right={rightToolbarTemplate}></Toolbar>

            <DataTable value={listaFestivos} paginator rows={10} dataKey="uuid" loading={loading} emptyMessage="No hay festivos."
                onSelectionChange={(e) => setFestivo(e.value)} selectionMode="single" selection={festivo} showGridlines
            >
                <Column field="fecha" header="Fecha" style={{ minWidth: '8rem' }}/>
            </DataTable>

            {visibleCrear === true && <CrearFestivo 
                visible={visibleCrear} setVisible={setVisibleCrear}
            />}
            {visibleEliminar === true && 
            <ModalConfirmacion 
                visible={visibleEliminar}
                setVisible={setVisibleEliminar}
                header='Eliminar Liquidación'
                body='¿Estás seguro que deseas eliminar el festivo?'
                action={onDelete}
            />
            }
        </Container>
    );
};

export default ListaFestivos;