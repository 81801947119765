import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { ISalario, ISalarioTable } from '../../features/salarios/Salarios.type';
import { formatDateForPost } from '../../util/DateUtil';
import { axiosInstance } from '../../util/AxiosInterceptor';

export interface SalarioInitialState {
    salario: ISalario,
    tablaSalarios: ISalarioTable,
    loading: boolean,
    error: string | undefined
}

const initialState: SalarioInitialState = {
    salario: {},
    tablaSalarios: {salarios: [], totalSalarios: 0, promedioSalario: 0},
    loading: true,
    error: undefined
}

export const salarioSlice = createSlice({
    name : 'salarios',
    initialState,
    reducers: {
        setSalario: (state, action) =>{
            state.salario = action.payload;
        },
        setTablaSalarios : (state, action) => {
            state.tablaSalarios = action.payload;
        },
        setLoading : (state, action) => {
            state.loading = action.payload;
        },
        setError : (state, action) => {
            state.error = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSalarios.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchSalarios.fulfilled, (state, action) => {
                state.tablaSalarios = action.payload;
                state.loading = false;
            })
            .addCase(fetchSalarios.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const { setSalario, setTablaSalarios, 
    setLoading, setError } = salarioSlice.actions;

export default salarioSlice.reducer;

export const fetchSalarios = createAsyncThunk('salarios/fetchSalarios', async ({conductorDocumento, fechaInicio, fechaFinal} 
    : {conductorDocumento: string, fechaInicio: Date | string, fechaFinal: Date | string}) => {
    
    const conductor = conductorDocumento === '' || conductorDocumento === null ? null : conductorDocumento;
    const fechaIni = fechaInicio === '' || fechaInicio === null ? null : formatDateForPost(new Date(fechaInicio));
    const fechaFin = fechaFinal === '' || fechaFinal === null ? null : formatDateForPost(new Date(fechaFinal));
    const response = await axiosInstance.get('/salarios', 
        { params : {
            fechaInicial: fechaIni,
            fechaFinal: fechaFin,
            documento: conductor
        }}
    );
    return response.data;
});