import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { IDeuda, IDeudaTable } from '../../features/deudas/Deudas.type';
import { mostrarToast } from '../toast';
import { IMessage } from '../../shared/Message.type';
import { axiosInstance } from '../../util/AxiosInterceptor';


export interface DeudasInitialState {
    deuda: IDeuda,
    tablaDeudas: IDeudaTable,
    loading: boolean,
    error: string | undefined
}

const initialState: DeudasInitialState = {
    deuda: {},
    tablaDeudas: {deudas:[], balance:0, totalDeuda:0},
    loading: false,
    error: undefined
}

export const deudasSlice = createSlice({
    name : 'deudas',
    initialState,
    reducers: {
        setDeuda: (state, action) =>{
            state.deuda = action.payload;
        },
        setTablaDeudas : (state, action) => {
            state.tablaDeudas = action.payload;
        },
        setLoading : (state, action) => {
            state.loading = action.payload;
        },
        setError : (state, action) => {
            state.error = action.payload;
        },
        pushToListaDeudas: (state, action) => {
            state.tablaDeudas.deudas.push(action.payload);
        },
        editItemListaDeudas: (state, action) => {
            state.tablaDeudas.deudas = state.tablaDeudas.deudas.map(item => item.uuid === 
                action.payload.uuid ? action.payload : item);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDeudas.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchDeudas.fulfilled, (state, action) => {
                state.tablaDeudas = action.payload;
                state.loading = false;
            })
            .addCase(fetchDeudas.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const { setDeuda, setTablaDeudas, 
    setLoading, setError, pushToListaDeudas, editItemListaDeudas } = deudasSlice.actions;

export default deudasSlice.reducer;

export const fetchDeudas = createAsyncThunk('deudas/fetchDeudas', async (conductorDocumento: string) => {
    const conductor = conductorDocumento === '' || conductorDocumento === null ? null : conductorDocumento;
    const uri = conductor === null ? '/deudas' : '/deudas/conductor';
    const response = await axiosInstance.get(uri, 
        { params : {
            documento: conductor
        }}
    );
    return response.data;
});

export const createDeuda = (payload: IDeuda, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post('/deudas', payload)
    .then((response) => {
        const deuda = response?.data as IDeuda;
        dispatch(pushToListaDeudas(deuda));
        setVisible(false)
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const updateDeuda = (payload: IDeuda, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post('/deudas', payload)
    .then((response) => {
        const deuda = response?.data as IDeuda;
        dispatch(editItemListaDeudas(deuda));
        setVisible(false)
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const deleteDeuda = (payload: string) => (dispatch: any) => {
    axiosInstance
    .delete('/deudas/eliminar/'+payload)
    .then((response) => {
        const mensaje = response?.data as IMessage;
        dispatch(mostrarToast({severity: 'success', summary: '', detail: mensaje.mensaje}))
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};
