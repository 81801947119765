import { styled } from "styled-components";

export const Container = styled.div`
  display: block;
  width: 85%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
  min-height: 80vh;
`;


export const BackgroundLogin = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(circle, rgba(29,255,74,1) 0%, rgba(148,233,233,1) 100%);
`;

export const Background = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(circle, rgba(29,255,74,1) 0%, rgba(148,233,233,1) 100%);
`;

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: .7rem 1rem 10rem rgba(0, 0, 0, 0.5);
  min-height: 40vh;
`;
