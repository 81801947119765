import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { IConductor } from '../../features/conductores/Conductores.type';
import { mostrarToast } from '../toast';
import { axiosInstance } from '../../util/AxiosInterceptor';
import { IMessage } from '../../shared/Message.type';


export interface ConductorInitialState {
    conductor: IConductor,
    listaConductores: IConductor[],
    listaTablaConductores: IConductor[],
    loading: boolean,
    error: string | undefined
}

const initialState: ConductorInitialState = {
    conductor: {},
    listaConductores: [],
    listaTablaConductores: [],
    loading: true,
    error: undefined
}

export const conductoresSlice = createSlice({
    name : 'conductores',
    initialState,
    reducers: {
        setConductor: (state, action) =>{
            state.conductor = action.payload;
        },
        setListaConductores : (state, action) => {
            state.listaConductores = action.payload;
        },
        setListaConductoresTabla : (state, action) => {
            state.listaTablaConductores = action.payload;
        },
        setLoading : (state, action) => {
            state.loading = action.payload;
        },
        setError : (state, action) => {
            state.error = action.payload;
        },
        pushToListaConductores: (state, action) => {
            state.listaTablaConductores.push(action.payload);
        },
        editItemListaConductor: (state, action) => {
            state.listaTablaConductores = state.listaTablaConductores.map(item => item.documento === 
                action.payload.documento ? action.payload : item);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchConductores.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchConductores.fulfilled, (state, action) => {
                state.listaConductores = action.payload;
                state.loading = false;
            })
            .addCase(fetchConductores.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(fetchConductoresWithIngresos.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchConductoresWithIngresos.fulfilled, (state, action) => {
                state.listaTablaConductores = action.payload;
                state.loading = false;
            })
            .addCase(fetchConductoresWithIngresos.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const { setConductor, setListaConductores, 
    setLoading, setError, pushToListaConductores,
    editItemListaConductor, setListaConductoresTabla } = conductoresSlice.actions;

export default conductoresSlice.reducer;

export const fetchConductores = createAsyncThunk('conductores/fetchConductores', async () => {
    const response = await axiosInstance.get('/conductores');
    return response.data;
});

export const fetchConductoresWithIngresos = createAsyncThunk('conductores/fetchConductoresWithIngresos', async () => {
    const response = await axiosInstance.get('/conductores/ingresos');
    return response.data;
});

export const createConductor = (payload: IConductor, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post("/conductores ", payload)
    .then((response) => {
        const conductor = response?.data as IConductor;
        dispatch(pushToListaConductores(conductor));
        setVisible(false);
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const editarConductor = (payload: IConductor, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .put("/conductores/conductor", payload, {
        params: {
            documento: payload.documento
        }
    })
    .then((response) => {
        const conductorEditado = response?.data as IConductor;
        dispatch(editItemListaConductor(conductorEditado));
        setVisible(false);
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
}

export const retirarConductor = (documento: String, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .put("/conductores/conductor/retirar", null, {
        params: {
            documento: documento
        }
    })
    .then((response) => {
        const message = response?.data as IMessage;
        dispatch(mostrarToast({severity: 'success', summary: '', detail: message.mensaje}))
        setVisible(false);
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
}

export const ingresarConductor = (documento: String, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .put("/conductores/conductor/ingresar", null,  {
        params: {
            documento: documento
        }
    })
    .then((response) => {
        const message = response?.data as IMessage;
        dispatch(mostrarToast({severity: 'success', summary: '', detail: message.mensaje}))
        setVisible(false);
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
}