function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export const formatDate = (value: Date) => {
    return value.toLocaleDateString('es',
    {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
};

export const formatDateForPost = (value: Date) => {
  return [
    padTo2Digits(value.getMonth() + 1),
    padTo2Digits(value.getDate()),
    value.getFullYear()
  ].join('-')
};

export const validateFechas = (value: any, valueTwo: any) => {
  if((value && valueTwo) || (!value && !valueTwo)){
      return true;
  }
  return false;
}