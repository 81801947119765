import { history } from '../util/History';
import { axiosInstance } from "../util/AxiosInterceptor";
import { AxiosError, AxiosResponse } from 'axios';

export const login = (username: string, password: string) : any => {
  return axiosInstance
    .post("/auth/login", { username, password })
    .then((response: AxiosResponse) => {
      if (response.data.token) {
        localStorage.setItem("jwtData", JSON.stringify(response.data));
      }
      history.push('/');
      return response;
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
}

export const logout = () => {
  localStorage.removeItem("jwtData");
}