import React, { FC } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


interface IProps {
    visible: boolean;
    header: string;
    body: string;
    action: () => void;
    setVisible: (value: boolean) => void;
}

const ModalConfirmacion: FC<IProps> = ({visible, header, body, action, setVisible}) => {
    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
            style={{width: '40vw'}}
        >
            <div className='text-center text-2xl p-5 mb-4'>
                <p>{body}</p>
            </div>

            <div className='flex justify-content-evenly'>
                <Button
                    severity='secondary'
                    label="Cancelar"
                />
                <Button
                    label="Aceptar"
                    onClick={action}
                />
            </div>
        </Dialog>
    );
};

export default ModalConfirmacion;