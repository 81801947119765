import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { mostrarToast } from '../toast';
import { IMessage } from '../../shared/Message.type';
import { axiosInstance } from '../../util/AxiosInterceptor';
import { IFestivo } from '../../features/festivos/Festivos.type';

export interface FestivosInitialState {
    festivo: IFestivo,
    listaFestivos: IFestivo[],
    loading: boolean,
    error: string | undefined
}

const initialState: FestivosInitialState = {
    festivo: {},
    listaFestivos: [],
    loading: false,
    error: undefined
}

export const festivosSlice = createSlice({
    name : 'festivos',
    initialState,
    reducers: {
        setListaFestivos : (state, action) => {
            state.listaFestivos = action.payload;
        },
        setLoading : (state, action) => {
            state.loading = action.payload;
        },
        setError : (state, action) => {
            state.error = action.payload;
        },
        pushToListaFestivos: (state, action) => {
            state.listaFestivos.push(action.payload);
        },
        deleteFromListaFestivos: (state, action) => {
            state.listaFestivos = state.listaFestivos.filter(item => item.uuid !== action.payload)
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFestivos.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchFestivos.fulfilled, (state, action) => {
                state.listaFestivos = action.payload;
                state.loading = false;
            })
            .addCase(fetchFestivos.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const { setListaFestivos, 
    setLoading, setError, pushToListaFestivos, deleteFromListaFestivos } = festivosSlice.actions;

export default festivosSlice.reducer;

export const fetchFestivos = createAsyncThunk('festivos/fetchFestivos', async () => {
    const response = await axiosInstance.get('/festivos');
    return response.data;
});

export const createFestivo = (payload: IFestivo, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post('/festivos', payload)
    .then((response) => {
        const festivo = response?.data as IFestivo;
        dispatch(pushToListaFestivos(festivo));
        setVisible(false)
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const deleteFestivo = (uuid: string, setVisible: (value:boolean) => void) => (dispatch: any) => {
    axiosInstance
    .delete('/festivos', { params: { uuid }})
    .then((response) => {
        const mensaje = response?.data as IMessage;
        setVisible(false)
        dispatch(mostrarToast({severity: 'success', summary: '', detail: mensaje.mensaje}))
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};
