import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import React, { FC } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { IBus } from "./Buses.type";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { createBus } from "../../redux/buses";

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

type Inputs = {
  numero?: string;
  empresa?: string;
  ruta?: string;
  modelo?: string;
  marca?: string;
  placa?: string;
  conductorDocumento?: string;
};

const CrearBus: FC<Props> = ({ visible, setVisible }) => {

  const dispatch = useAppDispatch();

  const { listaConductores: conductores } = useAppSelector((state) => state.conductores);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues : {
      numero: '',
      empresa: '',
      ruta: '',
      modelo: '',
      marca: '',
      placa: '',
      conductorDocumento: ''
    }
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const bus = data as IBus;
    dispatch(createBus(bus, setVisible));
  };

  const rutas = [
    {
      codigo: "CCNT",
      descripcion: "Circular Coonatra",
    },
    {
      codigo: "CSUR3",
      descripcion: "Circular Sur 303",
    },
    {
      codigo: "CSUR2",
      descripcion: "Circular Sur 302",
    },
    {
      codigo: "CBOS",
      descripcion: "Calasanz Boston",
    },
  ];

  const getFormErrorMessage = (name: any) => {
    return errors[name as keyof Inputs] ? (
      <small className="p-error block">
        {errors[name as keyof Inputs]?.message}
      </small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <Dialog
      header="Formulario Bus"
      visible={visible}
      onHide={() => setVisible(false)}
      style={{width: "40vw", maxWidth: "40rem"  }}
    >
      <form
        id="form_liquidacion"
        className="formgrid grid"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="numero"
          control={control}
          rules={{
            required: "El número es requerido.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Número
              </label>
              <InputText
                id={field.name}
                value={field.value}
                className={classNames({ "p-invalid": fieldState.error })}
                onChange={(e) => field.onChange(e.target.value)}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="empresa"
          control={control}
          rules={{
            required: "La empresa es requerida.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Empresa
              </label>
              <InputText
                id={field.name}
                value={field.value}
                className={classNames({ "p-invalid": fieldState.error })}
                onChange={(e) => field.onChange(e.target.value)}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="ruta"
          control={control}
          rules={{ required: "La ruta es requerida." }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <p className="block" style={{marginBottom: '0.5rem'}}>
                Ruta
              </p>
              <Dropdown
                id={field.name}
                value={field.value}
                placeholder="Seleccione una ruta"
                options={rutas}
                optionLabel="descripcion"
                optionValue="codigo"
                focusInputRef={field.ref}
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="modelo"
          control={control}
          rules={{
            required: "El modelo es requerido.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Modelo
              </label>
              <InputText
                id={field.name}
                value={field.value}
                className={classNames({ "p-invalid": fieldState.error })}
                onChange={(e) => field.onChange(e.target.value)}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="marca"
          control={control}
          rules={{
            required: "La marca es requerida.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Marca
              </label>
              <InputText
                id={field.name}
                value={field.value}
                className={classNames({ "p-invalid": fieldState.error })}
                onChange={(e) => field.onChange(e.target.value)}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="placa"
          control={control}
          rules={{
            required: "La placa es requerida.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Placa
              </label>
              <InputText
                id={field.name}
                value={field.value}
                className={classNames({ "p-invalid": fieldState.error })}
                onChange={(e) => field.onChange(e.target.value)}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="conductorDocumento"
          control={control}
          rules={{ required: "El conductor es requerido." }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <p className="block" style={{marginBottom: '0.5rem'}}>
                Conductor
              </p>
              <Dropdown
                id={field.name}
                value={field.value}
                placeholder="Seleccione un conductor"
                options={conductores}
                optionLabel="nombre"
                optionValue="documento"
                focusInputRef={field.ref}
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="col-12">
          <Button
            className="mt-5 ml-2"
            label="Crear"
            type="submit"
            icon="pi pi-check"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default CrearBus;
