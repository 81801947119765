import "./App.css";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import React, { useEffect, useRef } from "react";
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from "react-router-dom";
import { locale, addLocale } from "primereact/api";

import ListaLiquidaciones from "./features/liquidaciones/ListaLiquidaciones";
import Menu from "./features/shared/Menu";
import ListaConductores from "./features/conductores/ListaConductores";
import ListaBuses from "./features/buses/ListaBuses";
import ListaPasajes from "./features/pasajes/ListaPasajes";
import ListaSalarios from "./features/salarios/ListaSalarios";
import ListaDeudas from "./features/deudas/ListaDeudas";
import ListaAbonos from "./features/abonos/ListaAbonos";

import { Toast } from "primereact/toast"; 

import { useAppSelector } from "./hooks/reduxHooks";
import Login from "./features/login/Login";
import { history } from "./util/History";
import ListaFestivos from "./features/festivos/ListaFestivos";



function App() {
  const message = useRef(null);
  
  const { toastMessage } = useAppSelector((store) => store.toast);

  locale("es");

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
    filter: "filtro",
    equals: "Igual a",
    startsWith: "Comienza con",
    contains: "Contiene",
    notContains: "No Contiene",
    endsWith: "Termina con",
    notEquals: "No es Igual a",
    noFilter: "Sin filtro",
    lt: "Menor a",
    lte: "Menor o Igual a",
    gt: "Mayor a",
    gte: "Mayor o Igual a",
    dateIs: "Fecha igual a",
    dateIsNot: "Fecha no es igual a",
    dateBefore: "Fecha menor a",
    dateAfter: "Fecha mayor a",
    apply: "Aplicar",
    matchAll: "Cumple todas",
    matchAny: "Cumple alguna",
    addRule: "Agregar regla",
    removeRule: "Remover regla",
    accept: "Aceptar",
    reject: "Rechazar",
    choose: "Seleccionar",
    upload: "Subir archivo",
    cancel: "Cancelar",
    close: "Cerrar",
  });

  useEffect(() => {
    if(toastMessage !== undefined && Object.keys(toastMessage).length > 0.){
      message.current.show(toastMessage);
    }
  }, [toastMessage])


  return (
    <HistoryRouter history={history}>
      <Toast ref={message}/>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Menu/>}>
                <Route path="" element={<ListaLiquidaciones />} />
                <Route path="conductores" element={<ListaConductores />} />
                <Route path="buses" element={<ListaBuses />} />
                <Route path="pasajes" element={<ListaPasajes />} />
                <Route path="salarios" element={<ListaSalarios />} />
                <Route path="deudas" element={<ListaDeudas />} />
                <Route path="abonos" element={<ListaAbonos />} />
                <Route path="festivos" element={<ListaFestivos />} />
            </Route>
        </Routes>
    </HistoryRouter>
  );
}

export default App;
