import "./Liquidaciones.scss";

import React, { useEffect, useState } from "react";
import { Container } from "../../components/Container";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { dateFilterTemplate,booleanFilterTemplate } from "../../services/TemplateServices";
import CrearLiquidacion from "./CrearLiquidacion";
import EditarLiquidacion from "./EditarLiquidacion";
import { ILiquidacion } from "./Liquidaciones.type";
import { classNames } from "primereact/utils";
import DetalleLiquidacion from "./DetalleLiquidacion";
import IngresarSensores from "./IngresarSensores";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { eliminarLiquidacion, fetchLiquidacionesFiltered } from '../../redux/liquidaciones';
import ModalConfirmacion from "../../components/ModalConfirmacion";
import { fetchBuses } from "../../redux/buses";
import { fetchConductores } from "../../redux/conductores";
import { Calendar } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Tooltip } from "primereact/tooltip";
import { validateFechas } from "../../util/DateUtil";


interface Inputs {
  busNumero: string;
  conductorDocumento: string ;
  liquidada: boolean;
  fechaInicio: Date | string ;
  fechaFinal: Date | string ;
}

const ListaLiquidaciones = () => {

  const [visibleCrear, setVisibleCrear] = useState(false);

  const [visibleLiquidar, setVisibleLiquidar] = useState(false);

  const [visibleDetalle, setVisibleDetalle] = useState(false);

  const [visibleInSensores, setVisibleInSensores] = useState(false);

  const [visibleEliminar, setVisibleEliminar] = useState(false);

  const [liquidacion, setLiquidacion] = useState<ILiquidacion>();

  const [liquidaciones, setLiquidaciones] = useState<ILiquidacion[]>([]);

  const [filters, setFilters] = useState({});

  const { listaLiquidaciones, loading } = useAppSelector((state) => state.liquidaciones);
  const { listaConductores } = useAppSelector((state) => state.conductores);
  const { listaBuses } = useAppSelector((state) => state.buses);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch
  } = useForm<Inputs>(
    {
      defaultValues: {
        busNumero: '',
        conductorDocumento: '',
        fechaInicio: '',
        fechaFinal: ''
      }
    }
  );

  const listaLiquidada = [
    {
      nombre: "Si",
      valor: true
    },
    {
      nombre: "No",
      valor: false
    }
  ]

  const changeLiquidadaInput = (e: DropdownChangeEvent, field: FieldValues) => {
    setValue('busNumero', '');
    setValue('conductorDocumento', '');
    console.log(e.value);
    field.onChange(e.value);
  }

  const getFormErrorMessage = (name: any) => {
    return errors[name as keyof Inputs] ? (
        <Tooltip 
            target={"#"+name}
            position='bottom'
        >
        {errors[name as keyof Inputs]?.message}
        </Tooltip>
    ) : (
        <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    validateFechas(data.fechaInicio, data.fechaFinal);
    dispatch(fetchLiquidacionesFiltered(data));
  }

  const limpiarBusqueda = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    reset();
  }

  useEffect(() => {
    initFilters();
    dispatch(fetchConductores());
    dispatch(fetchBuses());
  }, [dispatch]);


  useEffect(() => {
    setLiquidaciones([...(JSON.parse(JSON.stringify(listaLiquidaciones)) || [])].map((d) => {
      d.fecha = new Date(d.fecha!);
  
      return d;
    }))
  }, [listaLiquidaciones]);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      uuid: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      fecha: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      conductorNombre: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      pasajerosReg: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      valorNetoReg: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      pasajerosSensor: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      valorNetoSensor: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      liquidada: {
        value: null, matchMode: FilterMatchMode.EQUALS
      },
      busNumero: { operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}
    });
  };

  const formatDate = (value: Date) => {
    return value.toLocaleDateString('es',
    {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const eliminarLiquidacionUuid = () : void => {
    dispatch(eliminarLiquidacion(liquidacion!.uuid!, setVisibleEliminar));
  }

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Crear"
          icon="pi pi-plus"
          severity="success"
          onClick={() => setVisibleCrear(true)}
        />
        <Button
          label="Eliminar"
          icon="pi pi-trash"
          severity="danger"
          disabled={liquidacion === undefined}
          onClick={() => setVisibleEliminar(true)}
        />
        <Button
          label="Liquidar"
          icon="pi pi-pencil"
          severity="info"
          onClick={() => setVisibleLiquidar(true)}
          disabled={liquidacion === undefined}
        />
        <Button
          label="Detalle"
          icon="pi pi-eye"
          severity="secondary"
          onClick={() => setVisibleDetalle(true)}
          disabled={liquidacion === undefined}
        />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
            label="Ingresar Sensores"
            icon="pi pi-database"
            severity="warning"
            onClick={() => setVisibleInSensores(true)}
          />
        <Button label="Exportar" icon="pi pi-upload" className="p-button-help" />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex-row flex-wrap justify-content-between">
        <form 
            id="form_salarios"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-wrap gap-2 align-items-center"
            >
            <Controller
            name="busNumero"
            control={control}
            render={({ field, fieldState }) => (
                <div className="field">
                <p className="block" style={{marginBottom: '0.5rem'}}>
                    Bus
                </p>
                <Dropdown
                    id={field.name}
                    value={field.value}
                    optionLabel="numero"
                    optionValue="numero"
                    placeholder="Seleccione un bus"
                    options={listaBuses}
                    focusInputRef={field.ref}
                    showClear
                    disabled={watch('liquidada') !== undefined}
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ "p-invalid": fieldState.error })}
                />
                {getFormErrorMessage(field.name)}
                </div>
            )}
            />
            <Controller
            name="conductorDocumento"
            control={control}
            render={({ field, fieldState }) => (
                <div className="field">
                <p className="block" style={{marginBottom: '0.5rem'}}>
                    Conductor
                </p>
                <Dropdown
                    id={field.name}
                    value={field.value}
                    optionLabel="nombre"
                    optionValue="documento"
                    placeholder="Seleccione un conductor"
                    options={listaConductores}
                    focusInputRef={field.ref}
                    disabled={watch('liquidada') !== undefined}
                    showClear
                    onChange={(e) => field.onChange(e.value)}
                    className={classNames({ "p-invalid": fieldState.error })}
                />
                {getFormErrorMessage(field.name)}
                </div>
            )}
            />
            <Controller
            name="liquidada"
            control={control}
            render={({ field, fieldState }) => (
                <div className="field">
                <p className="block" style={{marginBottom: '0.5rem'}}>
                    Liquidada
                </p>
                <Dropdown
                    id={field.name}
                    value={field.value}
                    optionLabel="nombre"
                    optionValue="valor"
                    placeholder="Seleccione un valor"
                    options={listaLiquidada}
                    focusInputRef={field.ref}
                    showClear
                    onChange={(e) => changeLiquidadaInput(e, field)}
                    className={classNames({ "p-invalid": fieldState.error })}
                />
                {getFormErrorMessage(field.name)}
                </div>
            )}
            />
            <Controller
                name="fechaInicio"
                control={control}
                rules={{
                    required: "Las fechas son requeridas"
                }}
                render={({ field, fieldState }) => (
                    <div className="field">
                    <label htmlFor={field.name} className="block">
                        Fecha Inicio
                    </label>
                    <Calendar
                        inputId={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        dateFormat="dd/mm/yy"
                        className={classNames({ "p-invalid": fieldState.error })}
                        showIcon
                        showButtonBar
                    />
                    {getFormErrorMessage(field.name)}
                    </div>
                )}
            />
            <Controller
                name="fechaFinal"
                control={control}
                rules={{
                    required: "Las fechas son requeridas",
                    validate: (value) =>
                        value > getValues('fechaInicio')
                        || "La fecha final debe ser mayor a la inicial."
                }}
                render={({ field, fieldState }) => (
                    <div className="field">
                    <label htmlFor={field.name} className="block">
                        Fecha Final
                    </label>
                    <Calendar
                        inputId={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        dateFormat="dd/mm/yy"
                        className={classNames({ "p-invalid": fieldState.error })}
                        showIcon
                        showButtonBar
                    />
                    {getFormErrorMessage(field.name)}
                    </div>
                )}
            />
            <div>
                <Button label="Limpiar" severity="info" className='mt-3 ml-5' onClick={(e) => limpiarBusqueda(e)}/>
                <Button label="Buscar" severity="success" className='mt-3 ml-5'/>
            </div>
        </form>
        <Button
          type="button"
          icon="pi pi-filter-slash"
          className="filter-button"
          label="Limpiar Filtros Tabla"
          outlined
          onClick={() => clearFilter()}
        />
      </div>
    );
  };

  const liquidadaBodyTemplate = (rowData: ILiquidacion) => {
    return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.liquidada, 'text-red-500 pi-times-circle': !rowData.liquidada })}></i>;
  };

  const fechaBodyTemplate = (rowData: ILiquidacion) => {
    return formatDate(new Date(rowData.fecha!));
  };

  const rowClass = (data: ILiquidacion) => {
    return {
        'bg-yellow-liquidada': data.liquidada === false || data.liquidada === null
    };
  };

  const header = renderHeader();


  return (
    <Container>
      <Toolbar
        className="mb-3"
        start={leftToolbarTemplate}
        end={rightToolbarTemplate}
      ></Toolbar>
      <DataTable
        value={liquidaciones}
        paginator
        rows={10}
        dataKey="uuid"
        filters={filters}
        loading={loading}
        rowClassName={rowClass}
        emptyMessage="No hay liquidaciones."
        onSelectionChange={(e) => setLiquidacion(e.value)}
        selectionMode="single"
        selection={liquidacion}
        header={header}
        showGridlines
      >
        <Column
          field="uuid"
          header="Identificador"
          filter
          filterField="uuid"
          hidden
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="fecha"
          header="Fecha"
          dataType="date"
          filter
          filterField="fecha"
          sortable
          body={fechaBodyTemplate}
          style={{ minWidth: "6rem" }}
          filterElement={dateFilterTemplate}
        />
        <Column
          field="busNumero"
          header="Bus"
          filter
          sortable
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Buscar por número"
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="conductorNombre"
          header="Conductor"
          filterField="conductorNombre"
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="regIni"
          filterField="regIni"
          dataType="numeric"
          header="Registradora Inicial"
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="regFin"
          filterField="regFin"
          dataType="numeric"
          header="Registradora Final"
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="pasajerosReg"
          filterField="pasajerosReg"
          dataType="numeric"
          header="Pasajeros Registradora"
          filter
          style={{ minWidth: "6rem" }}
        />

        <Column
          field="pasajerosSensor"
          filterField="pasajerosSensor"
          dataType="numeric"
          header="Pasajeros Sensor"
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="valorNetoReg"
          filterField="valorNetoReg"
          dataType="numeric"
          header="Total Registradora"
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="valorNetoSensor"
          filterField="valorNetoSensor"
          dataType="numeric"
          header="Total Sensor"
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="liquidada"
          header="Liquidada"
          dataType="boolean"
          filter
          style={{ minWidth: "6rem" }}
          filterElement={(options) => booleanFilterTemplate(options, 'Liquidada')}
          bodyClassName="text-center"
          body={liquidadaBodyTemplate}
          hidden
        />
      </DataTable>

      {visibleCrear === true && <CrearLiquidacion 
        visible={visibleCrear} setVisible={setVisibleCrear}
      />}

      {visibleLiquidar === true && <EditarLiquidacion
        visible={visibleLiquidar}
        setVisible={setVisibleLiquidar}
        uuidLiquidacion={liquidacion!.uuid ?? ""}
        key={liquidacion!.uuid}
      />}
      {visibleDetalle === true && <DetalleLiquidacion
        visible={visibleDetalle}
        setVisible={setVisibleDetalle}
        uuidLiquidacion={liquidacion!.uuid ?? ""}
        key={liquidacion!.uuid}
      />}

      {visibleInSensores === true && <IngresarSensores 
        visible={visibleInSensores} setVisible={setVisibleInSensores}
      />} 

      {visibleEliminar === true && 
      <ModalConfirmacion 
        visible={visibleEliminar}
        setVisible={setVisibleEliminar}
        header='Eliminar Liquidación'
        body='¿Estás seguro que deseas eliminar la liquidación?'
        action={eliminarLiquidacionUuid}
      />
      }
      
    </Container>
  );
};

export default ListaLiquidaciones;
