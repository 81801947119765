import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { mostrarToast } from '../toast';
import { IMessage } from '../../shared/Message.type';
import { IAbono } from '../../features/liquidaciones/Liquidaciones.type';
import { axiosInstance } from '../../util/AxiosInterceptor';
import { IAbonoTable } from '../../features/abonos/Abonos.type';


export interface AbonosInitialState {
    abono: IAbono,
    tablaAbonos: IAbonoTable,
    loading: boolean,
    error: string | undefined
}

const initialState: AbonosInitialState = {
    abono: {},
    tablaAbonos: {abonos: [], totalAbono: 0},
    loading: false,
    error: undefined
}

export const abonosSlice = createSlice({
    name : 'abonos',
    initialState,
    reducers: {
        setAbono: (state, action) =>{
            state.abono = action.payload;
        },
        setTablaAbonos : (state, action) => {
            state.tablaAbonos = action.payload;
        },
        setLoading : (state, action) => {
            state.loading = action.payload;
        },
        setError : (state, action) => {
            state.error = action.payload;
        },
        pushToListaAbonos: (state, action) => {
            state.tablaAbonos.abonos.push(action.payload);
        },
        editItemListaAbonos: (state, action) => {
            state.tablaAbonos.abonos = state.tablaAbonos.abonos.map(item => item.uuid === 
                action.payload.uuid ? action.payload : item);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAbonos.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAbonos.fulfilled, (state, action) => {
                state.tablaAbonos = action.payload;
                state.loading = false;
            })
            .addCase(fetchAbonos.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const { setAbono, setTablaAbonos, 
    setLoading, setError, pushToListaAbonos, editItemListaAbonos } = abonosSlice.actions;

export default abonosSlice.reducer;

export const fetchAbonos = createAsyncThunk('abonos/fetchAbonos', async (conductorDocumento: string) => {
    const conductor = conductorDocumento === '' || conductorDocumento === null ? null : conductorDocumento;
    const uri = conductor === null ? '/abonos' : '/abonos/conductor';
    const response = await axiosInstance.get(uri, 
        { params : {
            documento: conductor
        }}
    );
    return response.data;
});

export const createAbono = (payload: IAbono, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post('/abonos', payload)
    .then((response) => {
        const abono = response?.data as IAbono;
        dispatch(pushToListaAbonos(abono));
        setVisible(false)
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const updateAbono = (payload: IAbono, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .put('/abonos', payload)
    .then((response) => {
        const abono = response?.data as IAbono;
        dispatch(editItemListaAbonos(abono));
        setVisible(false)
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const deleteAbono = (payload: string) => (dispatch: any) => {
    axiosInstance
    .delete('/abonos/eliminar/'+payload)
    .then((response) => {
        const mensaje = response?.data as IMessage;
        dispatch(mostrarToast({severity: 'success', summary: '', detail: mensaje.mensaje}))
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};
