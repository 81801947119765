import React, { useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';

import { Dropdown } from 'primereact/dropdown';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import CrearAbono from './CrearAbono';
import EditarAbono from './EditarAbono';

import { deleteAbono, fetchAbonos } from '../../redux/abonos';
import { IAbono } from './Abonos.type';
import { fetchConductores } from '../../redux/conductores';

interface Inputs {
    conductorDocumento: string ;
}


const ListaAbonos = () => {
    const dispatch = useAppDispatch();
    const { tablaAbonos, loading } = useAppSelector((state) => state.abonos);

    const [abono, setAbono] = useState<IAbono>();
    const [visibleCrear, setVisibleCrear] = useState(false);
    const [visibleEditar, setVisibleEditar] = useState(false);

    const {
        control,
        handleSubmit,
      } = useForm<Inputs>(
        {
          defaultValues: {
            conductorDocumento: ''
          }
        }
      );

    const { listaConductores } = useAppSelector((state) => state.conductores);

    useEffect(() => {
        if(listaConductores.length === 0){
            dispatch(fetchConductores());
        }
    }, [dispatch, listaConductores])

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        dispatch(fetchAbonos(data.conductorDocumento));
    }

    const onDelete = () => {
        dispatch(deleteAbono(abono!.uuid!));
    }

    const leftToolbarTemplate = () => {
        return (
            
            <form 
                id="form_abonos"
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-wrap gap-2 align-items-center"
            >
                <Controller
                name="conductorDocumento"
                control={control}
                render={({ field, fieldState }) => (
                    <div className="field">
                    <p className="block" style={{marginBottom: '0.5rem'}}>
                        Conductor
                    </p>
                    <Dropdown
                        id={field.name}
                        value={field.value}
                        optionLabel="nombre"
                        optionValue="documento"
                        placeholder="Seleccione un conductor"
                        options={listaConductores}
                        focusInputRef={field.ref}
                        showClear
                        onChange={(e) => field.onChange(e.value)}
                        className={classNames({ "p-invalid": fieldState.error })}
                    />
                    </div>
                )}
                />
                <div>
                    <Button label="Buscar" severity="success" className='mt-3 ml-5'/>
                </div>
            </form>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button
                label="Crear"
                icon="pi pi-plus"
                severity="success"
                onClick={() => setVisibleCrear(true)}
                />
                <Button
                label="Eliminar"
                icon="pi pi-trash"
                severity="danger"
                disabled={!abono}
                onClick={() => onDelete()}
                />
                <Button
                label="Editar"
                icon="pi pi-pencil"
                severity="info"
                onClick={() => setVisibleEditar(true)}
                disabled={!abono}
                />
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help"/>
            </div>
        );
    };

    const renderFooter = () => {
        return (
          <div className="flex flex-wrap justify-content-between">
            <p>Total de abonos: {tablaAbonos.totalAbono}</p>
          </div>
        );
      };

    return (
        <Container>
            <Toolbar className="mb-3" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <DataTable value={tablaAbonos.abonos} paginator rows={10} dataKey="uuid" loading={loading} emptyMessage="No hay abonos."
                onSelectionChange={(e) => setAbono(e.value)} selectionMode="single" selection={abono} showGridlines footer={renderFooter}
            >
                <Column field="valor" header="Valor" dataType='number' style={{ minWidth: '8rem' }} />
                <Column field="conductorNombre" header="Conductor" style={{ minWidth: '8rem' }} />
                <Column field="origen" header="Origen" style={{ minWidth: '8rem' }}/>
                <Column field="fecha" header="Fecha" style={{ minWidth: '8rem' }}/>
            </DataTable>

            {visibleCrear === true && <CrearAbono 
                visible={visibleCrear} setVisible={setVisibleCrear}
            />}
            {visibleEditar === true && <EditarAbono 
                visible={visibleEditar} setVisible={setVisibleEditar} abono={abono!}
            />}
        </Container>
    );
};

export default ListaAbonos;