import { Dialog } from 'primereact/dialog';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { fetchDetalleLiquidacion } from '../../redux/liquidaciones';

interface Props {
    visible: boolean;
    setVisible: (value: boolean) => void;
    uuidLiquidacion: string;
}

interface IValores {
    header: string;
    valor: any;
    type: string;
}

const DetalleLiquidacion: FC<Props> = ({visible, setVisible, uuidLiquidacion}) => {
    const dispatch = useAppDispatch();
    const { liquidacion } = useAppSelector((state) => state.liquidaciones);

    const [valoresBase, setValoresBase] = useState<IValores[]>([]);

    useEffect(() => {
        dispatch(fetchDetalleLiquidacion(uuidLiquidacion))
    }, [dispatch, uuidLiquidacion]);

    useEffect(() => {
        if(liquidacion !== undefined){
          setValoresBase(
            [
                {header: 'Fecha', valor: liquidacion.fecha, type:'date'},
                {header: 'Bus', valor: liquidacion.busNumero, type:'text'},
                {header: 'Conductor', valor: liquidacion.conductorNombre, type:'text'},
                {header: 'Viajes', valor: liquidacion.viajes, type:'text'},
                {header: 'Sensores Totales', valor: liquidacion.sensor, type: 'text'},
                {header: 'Pasajeros Sensor', valor: liquidacion.sensorNetos, type:'text'},
                {header: 'Valor Neto Sensor', valor: liquidacion.valorNeto, type:'text'},
                {header: 'Salario Sensor', valor: liquidacion.salario, type:'text'},
                {header: 'Registradora Final', valor: liquidacion.regFin, type:'text'},
                {header: 'Registradora Inicial', valor: liquidacion.regIni, type:'text'},
                {header: 'Pasajeros Registradora', valor: liquidacion.pasajerosRegistradora, type:'text'},
                {header: 'Valor Neto Registradora', valor: liquidacion.valorNetoRegistradora, type:'text'},
                {header: 'Salario Registradora', valor: liquidacion.salarioRegistradora, type:'text'},
                {header: 'Tiquetes', valor: liquidacion.tiquetes, type:'text'},
                {header: 'Civica', valor: liquidacion.civica, type:'text'},
                {header: 'Comentario', valor: liquidacion.comentario, type:'text'},
                {header: 'Abono', valor: liquidacion.abonoDto?.valor, type:'text'},
                {header: 'Deuda', valor: liquidacion.deudaDto?.valor, type:'text'},
                {header: 'Liquidada', valor: liquidacion.liquidada, type:'boolean'},
            ])
        }
      }, [liquidacion]);


    return (
        <Dialog
            header="Detalle Liquidación"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{width: "40vw", maxWidth: "40rem"  }}
        >
            <div
                className='grid'
            >
                {valoresBase.map((value, index) => 
                    value.valor !== undefined &&
                    <div className='col-6 p-3 my-1'>
                        { value.type === 'text' &&
                        <p className='text-lg font-bold'>{value.header}: <span className='font-normal'>{value.valor}</span></p>
                        }
                        { value.type === 'boolean' &&
                        <p className='text-lg font-bold'>{value.header} <span className='font-normal'>{value.valor === true ? <i className="pi pi-check text-xl"></i> : <i className="pi pi-times text-xl"></i>}</span></p>
                        }
                        { value.type === 'date' &&
                        <p className='text-lg font-bold'>{value.header}: <span className='font-normal'>{new Date(value.valor).toLocaleDateString('es',
                        {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric'
                        })}</span>
                        </p>
                        }
                    </div>
                )}
            {(liquidacion.gastos !== undefined && liquidacion.gastos!.length !== 0) &&
                <div className='col-12 bg-primary py-3'>
                    <ul>
                        <p className='text-4xl font-bold text-left mb-4'>Gastos</p>
                        {liquidacion.gastos?.map((gasto, index) =>
                            <li className='text-xl font-bold mt-2'>{gasto.nombre}: <span className='font-normal'>{gasto.valor}</span></li>
                        )}
                    </ul>
                </div>
            }
            </div>
        </Dialog>
    );
};

export default DetalleLiquidacion;